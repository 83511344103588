<mat-sidenav-container>
  <mat-sidenav #sidenav>
    <mat-nav-list>
      <ng-container *ngFor="let navItem of navItems">
        <div *ngIf="navItem.name !== 'divider'">
          <a *ngIf="navItem.route != '/auth/signup' || loggedIn == false"
            mat-list-item
            (click)="sidenav.close()"
            routerLinkActive
            #routerLinkActiveInstance="routerLinkActive"
            [attr.tabindex]="routerLinkActiveInstance.isActive ? 0 : -1"
            [routerLink]="[navItem.route]"
            >
            {{navItem.name}}
          </a>
        </div>
        <mat-divider *ngIf="navItem.name === 'divider'"></mat-divider>
      </ng-container>
      <mat-divider></mat-divider>
      <a mat-list-item href="javascript:window.location.reload();">리로드</a>
    </mat-nav-list>
  </mat-sidenav>
  <div>
    <mat-toolbar color="primary">
      <button mat-icon-button style="z-index: 10000; background-color: #3f51b5;" (click)="sidenav.open('mouse')">
        <mat-icon>menu</mat-icon>
      </button>

      <h1>{{title}}</h1>

      <div class="right">
        <button *ngIf="myVersion != latestVersion" mat-raised-button color="accent" (click)="reload()">업데이트</button>

        <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>help</mat-icon></button>
        <mat-menu class="menu-buttons" #menu="matMenu">
          <span mat-menu-item *ngIf="loggedIn == true">{{ email }} [{{ role }}]</span>
          <button mat-menu-item *ngIf="loggedIn == true" (click)="logout()">
            <mat-icon>exit_to_app</mat-icon>
            <span>로그아웃</span>
          </button>
          <mat-divider></mat-divider>
          <span mat-menu-item class="version">현재 버전:{{myVersion}}</span>
          <span mat-menu-item class="version" *ngIf="myVersion != latestVersion">최신 버전:{{latestVersion}}</span>
        </mat-menu>
      </div>
    </mat-toolbar>

    <div class="container">
      <router-outlet></router-outlet>
    </div>
  </div>
</mat-sidenav-container>
