import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  CanActivate, Route,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild, CanLoad
} from '@angular/router';
import { UserService } from './core/3/user.service';

/**
 * 개별 페이지를 표시하기 전에 준비해야 할 것이 있으면 기다리도록 한다.
 * - conf/organization
 */
@Injectable({
  providedIn: 'root'
})
export class InitGuardService implements CanActivate, CanActivateChild, CanLoad {

  constructor(
    private userService: UserService,
  ) { }

  promiseForInit() {
    return new Promise<boolean>(async (resolve, reject) => {
      const userOb = this.userService.latestUserSubject;

      try {
        await combineLatest([userOb]).pipe(
          takeWhile(([user]) => !(user?.role))
        ).forEach(() => { /* 끝나기만 기다릴 뿐 */ });
      } catch (err) {
        reject(err);
      }

      resolve(true);
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // console.log(`canActivate: '${route.url}'`);

    return this.promiseForInit();
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // console.log(`canActivateChild: '${childRoute.url}'`);

    return this.promiseForInit();
  }

  canLoad(route: Route) {
    // console.log(`canLoad: '${route.path}'`);

    return this.promiseForInit();
  }
}

