import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { roleMappings } from './core/1/string-map';
import { UtilService } from './core/1/util.service';
import { AuthService } from './core/2/auth.service';
import { VersionService } from './core/3/version.service';
import { UserService } from './core/3/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  loggedIn = false;
  email = '';
  myVersion: string;
  latestVersion: string;

  title = 'Learn Web API';
  navItems = [
    {name: 'Canvas basic', route: '/canvas-basic'},
    {name: 'Canvas Art1', route: '/canvas-art1'},
    {name: 'Canvas Ball', route: '/canvas-ball'},
    {name: 'divider'},
    {name: 'Background Tasks API', route: '/background-tasks-api'},
    {name: 'Background Tasks API2', route: '/background-tasks-api2'},
    {name: 'Beacon API', route: '/beacon-api'},
    {name: 'Bluetooth API', route: '/web-bluetooth-api'},
    {name: 'Broadcast Channel API', route: '/broadcast-channel-api'},
    {name: 'divider'},
    {name: 'CSS Font Loading API', route: '/css-font-loading-api'},
    {name: 'Console API', route: '/console-api'},
    {name: 'Channel Messaging API', route: '/channel-messaging-api'},
    {name: 'Clipboard API', route: '/clipboard-api'},
    {name: 'Contact Picker API', route: '/contact-picker-api'},
    {name: 'divider'},
    {name: 'Encoding API', route: '/encoding-api'},
    {name: 'Performance API', route: '/performance-api'},
    {name: 'PerformanceNavigationTiming', route: '/performance-navigation-timing'},
    {name: 'divider'},
    {name: 'Fetch API', route: '/fetch-api'},
    {name: 'Fullscreen API', route: '/fullscreen-api'},
    {name: 'divider'},
    {name: 'ImageCapture API', route: '/image-capture-api'},
    {name: 'History API', route: '/history-api'},
    {name: 'divider'},
    {name: 'MediaCapabilities API', route: '/media-capabilities-api'},
    {name: 'MediaSession API', route: '/media-session-api'},
    {name: 'MediaStream Recording API', route: '/media-stream-recording-api'},
    {name: 'Intersection Observer API', route: '/intersection-observer-api'},
    {name: 'Resize Observer API', route: '/resize-observer-api'},
    {name: 'Page Visibility API', route: '/page-visibility-api'},
    {name: 'Web Speech API', route: '/web-speech-api'},
    {name: 'Picture-in-Picture API', route: '/picture-in-picture-api'},
    {name: 'Pointer Lock API', route: '/pointer-lock-api'},
    {name: 'Payment Request API', route: '/payment-request-api'},
    {name: 'Server Sent Events', route: '/server-sent-events'},
    {name: 'Pointer Events', route: '/pointer-events'},
    {name: 'divider'},
    {name: 'Vibration', route: '/vibration'},
    {name: 'Visual Viewport', route: '/visual-viewport'},
  ];

  private subscription: Subscription = null;
  private versionSubscription: Subscription = null;

  constructor(
    private router: Router,
    private authService: AuthService,
    private utilService: UtilService,
    private versionService: VersionService,
    private userService: UserService,
  ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // console.dir(router.routerState);
        console.log(`urlAfterRedirects = ${event.urlAfterRedirects}`);

        for (const navItem of this.navItems) {
          // ex. /firestore/baeminAppDetailOrder/B0MP01BVXL
          const rootPath = '/' + event.urlAfterRedirects.split('/')[1];
          // console.log(`rootPath = ${rootPath}`);
          if (navItem.route === rootPath) {
            this.title = `${navItem.name} / Learn Web API `;
            document.title = navItem.name;
            break;
          }
        }
      }
    });
  }

  ngOnInit() {
    this.subscription = this.authService.observeLoggedIn().pipe(
      // 안정화되지 않은 상태인 null 을 제거하기 위함이다.
      filter(value => value === true || value === false)
    ).subscribe(async value => {
      console.log(`@AppComponent loggedIn = ${value}`);
      this.loggedIn = value;
      if (value === true) {
        this.utilService.toastrInfo('로그인 성공!', null, 3000);
        this.email = this.authService.user.email;

        // 아래 4가지는 init-guard의 조건이기도 하다.
        // 여기에서 시작을 하고 init-guard에서는 데이터가 수신 완료되는 것을 기다린다.
        // TODO: 로그아웃 후에 기존의 데이터를 초기화하도록 확인한다.
        this.userService.observe(this.email);
      }
    });

    this.myVersion = this.versionService.myVersion;
    this.latestVersion = this.versionService.latestVersion;
    this.versionSubscription = this.versionService.latestVersionSubject.subscribe(lastesVersion => {
      this.latestVersion = lastesVersion;
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.versionSubscription) {
      this.versionSubscription.unsubscribe();
    }
  }

  get role() {
    return this.userService.user ? roleMappings[this.userService.user.role] : '...';
  }

  logout() {
    this.authService.signOut().then(value => {
      this.router.navigate(['/auth/login']);
    });
  }

  reload() {
    window.location.reload();
  }

  // private injectionTest() {
  //   const injector = (window as any).rootInjector as Injector;
  //   console.dir(injector);
  //   const logService = injector.get(LogService);
  //   logService.withToastrError('코드를 통한 서비스 접근');
  // }
}
