import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService } from './auth-guard.service';
import { InitGuardService } from './init-guard.service';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'visual-viewport',
    canActivateChild: [AuthGuardService, InitGuardService],
  },
  {
    path: 'background-tasks-api',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/background-tasks-api/background-tasks-api.module').then(m => m.BackgroundTasksApiModule)
  },
  {
    path: 'background-tasks-api2',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/background-tasks-api2/background-tasks-api2.module').then(m => m.BackgroundTasksApi2Module)
  },
  {
    path: 'beacon-api',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/beacon-api/beacon-api.module').then(m => m.BeaconApiModule)
  },
  {
    path: 'web-bluetooth-api',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/web-bluetooth-api/web-bluetooth-api.module').then(m => m.WebBluetoothApiModule)
  },
  {
    path: 'broadcast-channel-api',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/broadcast-channel-api/broadcast-channel-api.module').then(m => m.BroadcastChannelApiModule)
  },
  {
    path: 'broadcast-channel-api-sub',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/broadcast-channel-api-sub/broadcast-channel-api-sub.module').then(m => m.BroadcastChannelApiSubModule)
  },
  {
    path: 'css-font-loading-api',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/css-font-loading-api/css-font-loading-api.module').then(m => m.CssFontLoadingApiModule)
  },
  {
    path: 'console-api',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/console-api/console-api.module').then(m => m.ConsoleApiModule)
  },
  {
    path: 'clipboard-api',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/clipboard-api/clipboard-api.module').then(m => m.ClipboardApiModule)
  },
  {
    path: 'channel-messaging-api',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/channel-messaging-api/channel-messaging-api.module').then(m => m.ChannelMessagingApiModule)
  },
  {
    path: 'contact-picker-api',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/contact-picker-api/contact-picker-api.module').then(m => m.ContactPickerApiModule)
  },
  {
    path: 'channel-messaging-api-sub',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/channel-messaging-api-sub/channel-messaging-api-sub.module').then(m => m.ChannelMessagingApiSubModule)
  },
  {
    path: 'encoding-api', loadChildren: () => import('./pages/encoding-api/encoding-api.module').then(m => m.EncodingApiModule),
    canActivate: [AuthGuardService, InitGuardService],
  },
  {
    path: 'performance-api', loadChildren: () => import('./pages/performance-api/performance-api.module').then(m => m.PerformanceAPIModule),
    canActivate: [AuthGuardService, InitGuardService],
  },
  {
    path: 'performance-navigation-timing',
    loadChildren: () => import('./pages/performance-navigation-timing/performance-navigation-timing.module').then(m => m.PerformanceNavigationTimingModule),
    canActivate: [AuthGuardService, InitGuardService],
  },
  {
    path: 'fetch-api', loadChildren: () => import('./pages/fetch-api/fetch-api.module').then(m => m.FetchApiModule),
    canActivate: [AuthGuardService, InitGuardService],
  },
  {
    path: 'fullscreen-api',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/fullscreen-api/fullscreen-api.module').then(m => m.FullscreenApiModule)
  },
  {
    path: 'image-capture-api',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/image-capture-api/image-capture-api.module').then(m => m.ImageCaptureApiModule)
  },
  {
    path: 'history-api',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/history-api/history-api.module').then(m => m.HistoryApiModule)
  },
  {
    path: 'media-capabilities-api',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/media-capabilities-api/media-capabilities-api.module').then(m => m.MediaCapabilitiesApiModule)
  },
  {
    path: 'media-session-api',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/media-session-api/media-session-api.module').then(m => m.MediaSessionApiModule)
  },
  {
    path: 'media-stream-recording-api',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/media-stream-recording-api/media-stream-recording-api.module').then(m => m.MediaStreamRecordingApiModule)
  },
  {
    path: 'intersection-observer-api',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/intersection-observer-api/intersection-observer-api.module').then(m => m.IntersectionObserverApiModule)
  },
  {
    path: 'resize-observer-api',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/resize-observer-api/resize-observer-api.module').then(m => m.ResizeObserverApiModule)
  },
  {
    path: 'page-visibility-api',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/page-visibility-api/page-visibility-api.module').then(m => m.PageVisibilityApiModule)
  },
  {
    path: 'web-speech-api',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/web-speech-api/web-speech-api.module').then(m => m.WebSpeechApiModule)
  },
  {
    path: 'canvas-basic',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/canvas-basic/canvas-basic.module').then(m => m.CanvasBasicModule)
  },
  {
    path: 'canvas-art1',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/canvas-art1/canvas-art1.module').then(m => m.CanvasArt1Module)
  },
  {
    path: 'picture-in-picture-api',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/picture-in-picture-api/picture-in-picture-api.module').then(m => m.PictureInPictureApiModule)
  },
  {
    path: 'pointer-lock-api',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/pointer-lock-api/pointer-lock-api.module').then(m => m.PointerLockApiModule)
  },
  {
    path: 'payment-request-api',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/payment-request-api/payment-request-api.module').then(m => m.PaymentRequestApiModule)
  },
  {
    path: 'server-sent-events',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/server-sent-events/server-sent-events.module').then(m => m.ServerSentEventsModule)
  },
  {
    path: 'screen-capture-api',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/screen-capture-api/screen-capture-api.module').then(m => m.ScreenCaptureApiModule)
  },
  {
    path: 'pointer-events',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/pointer-events/pointer-events.module').then(m => m.PointerEventsModule)
  },
  {
    path: 'touch-event',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/touch-event/touch-event.module').then(m => m.TouchEventModule)
  },
  { path: 'visual-viewport', loadChildren: () => import('./pages/visual-viewport/visual-viewport.module').then(m => m.VisualViewportModule) },
  { path: 'vibration', loadChildren: () => import('./pages/vibration/vibration.module').then(m => m.VibrationModule) },
  {
    path: 'canvas-ball',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/canvas-ball/canvas-ball.module').then(m => m.CanvasBallModule)
  },
  {
    path: '**',
    redirectTo: 'background-tasks-api'
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
