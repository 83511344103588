import { BehaviorSubject, Subscription } from 'rxjs';
import firestore from 'firebase/app';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { UtilService } from '../1/util.service';
import { UserDoc } from '../2/schema';

const collectionPath = 'user';

/**
 * 최신 버전을 유지하며 변경될 때마다 알려준다.
 */
@Injectable({
  providedIn: 'root'
})
export class UserService {
  user: UserDoc;
  latestUserSubject = new BehaviorSubject<UserDoc>(null);
  subscription: Subscription;

  constructor(
    private db: AngularFirestore,
    private utilService: UtilService
  ) {
  }

  /**
   * 최신 상태를 유지하며 변화가 있으면 알려준다.
   */
  observe(email: string) {
    console.log(`email = ${email}`);
    const docRef = this.db.doc<UserDoc>(`${collectionPath}/${email}`);

    this.subscription = docRef.valueChanges().subscribe(doc => {
      this.user = doc;

      this.latestUserSubject.next(doc);
    }, error => {
      this.utilService.toastrError(`UserService:observe에서 에러 발생 : ${error}`);
    });
  }

  observeUser() {
    const userCollection = this.db.collection<UserDoc>(collectionPath);

    const observable = userCollection.valueChanges();

    return observable;
  }

  updateUser(user: UserDoc, field: string, value: any) {
    const timestamp = firestore.firestore.FieldValue.serverTimestamp() as firestore.firestore.Timestamp;
    return this.db.doc(`${collectionPath}/${user.email}`).update({
      [field]: value,
      _timeMerge: timestamp,
    });
  }

  createUser(user: Partial<UserDoc>) {
    const timestamp = firestore.firestore.FieldValue.serverTimestamp() as firestore.firestore.Timestamp;

    const doc: Partial<UserDoc> = { ...user,
      _timeCreate: timestamp,
    };

    const docRef = this.db.firestore.doc(`${collectionPath}/${user.email}`);

    return this.db.doc<UserDoc>(docRef).set(doc as UserDoc);
  }

}
