/* eslint-disable no-underscore-dangle */
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { environment } from '../../../environments/environment';
import { VersionDoc } from '../2/schema';

const myVersion = environment.version;
const versionDocPath = 'conf/version';

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  public latestVersionSubject = new BehaviorSubject<string>(myVersion);
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  private _latestVersion = myVersion;

  constructor(private db: AngularFirestore) {
    this.observeVersion();
  }

  get myVersion() {
    return environment.version;
  }

  get latestVersion() {
    return this._latestVersion;
  }

  /**
   *
   */
  observeVersion() {
    // let doc: AngularFirestoreDocument<VroongPickup>;

    const doc = this.db.doc<VersionDoc>(versionDocPath);

    doc.valueChanges().subscribe(version => {
      this._latestVersion = version.learnWebAPIVer;
      console.dir(`lateast version = ${this.latestVersion}`);
      this.latestVersionSubject.next(this._latestVersion);
    });
  }
}


