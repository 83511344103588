import { Observable, BehaviorSubject } from 'rxjs';
import firebase from 'firebase/app';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

// const firebase = require('firebase/app');
import { DebugAuthService } from '../1/debug-auth.service';


/*
  아래는 console에 표시된 경고
  It looks like you're using the development build of the Firebase JS SDK.
  When deploying Firebase apps to production, it is advisable to only import
  the individual SDK components you intend to use.
  For the module builds, these are available in the following manner
  (replace <PACKAGE> with the name of a component - i.e. auth, database, etc):

  CommonJS Modules:  (이것도 typescript에서 가능)
  const firebase = require('firebase/app');
  require('firebase/<PACKAGE>');

  ES Modules:
  import firebase from 'firebase/app';
  import 'firebase/<PACKAGE>';

  typescript에서는 다음과 같이 하면 되는 듯 한데....
  import * as firebase from 'firebase/app');
*/

/**
 * refer: https://firebase.google.com/docs/reference/js/firebase.auth
 *
 * @export
 */

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loggedInSubject = new BehaviorSubject<boolean>(null);
  // loggedInSubject = new Subject<boolean>();
  user: firebase.User = null;

  constructor(
    private afAuth: AngularFireAuth,
    private debugAuthService: DebugAuthService
  ) {
    // console.log(firebase.auth.EmailAuthProvider.PROVIDER_ID);                   // password
    // console.log(firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD); // password
    // console.log(firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD);     // emailLink

    this.afAuth.onAuthStateChanged(user => {
      this.user = user;
      if (user) {
        console.log(`onAuthStateChanged logged in`);
        console.log(`@AuthService email = ${user.email}`);
        this.loggedInSubject.next(true);

        // debugFirebaseService.logUser(user);
      } else {
        console.log('onAuthStateChanged not logged in');

        this.loggedInSubject.next(false);
      }
    });
  }

  signupWithEmailAndPassword(email: string, password: string ) {
    return this.afAuth.createUserWithEmailAndPassword(email, password)
    .then((userCredential: firebase.auth.UserCredential) => {
      console.log('signup OK:');
      this.debugAuthService.logUserCredential(userCredential);
      return true;
    }, error => {
      // error.code
      // 'auth/weak-password'
      // 'auth/invalid-email'
      // 'auth/email-already-in-use'
      // 'auth/operation-not-allowed'
      console.log('signup FAIL:');
      console.dir(error);
      return Promise.reject(error);
    });
  }

  signinWithEmailAndPassword(email: string, password: string ) {
    return this.afAuth.signInWithEmailAndPassword(email, password)
    .then((userCredential: firebase.auth.UserCredential) => {
      console.log('signin OK:');
      this.debugAuthService.logUserCredential(userCredential);
      return true;
    }, error => {
      // error.code
      // 'auth/weak-password'
      // 'auth/invalid-email'
      // 'auth/email-already-in-use'
      // 'auth/operation-not-allowed'
      console.log('signin FAIL:');
      console.dir(error);
      return Promise.reject(error);
    });
  }

  signOut() {
    return this.afAuth.signOut();
  }

  observeLoggedIn(): Observable<boolean> {
    return this.loggedInSubject.asObservable();
  }
}
