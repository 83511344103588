import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    // refer : https://github.com/scttcper/ngx-toastr
    ToastrModule.forRoot({
      timeOut: 30000,
      closeButton: false,
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true
    }),
    SharedModule,
    AuthModule,
    AppRoutingModule,

    MatMenuModule,
    MatIconModule,
    MatDividerModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatDialogModule,
    MatButtonModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  // refer: https://stackoverflow.com/questions/42461852/inject-a-service-manually
  // root Injector를 빼내는 방법
  // constructor(private injector: Injector) {
  //   (window as any).rootInjector = injector;
  //   // console.dir(injector);
  // }
}

